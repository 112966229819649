<template>
  <div class="header" :class="{ fixed: fixed }">
    <div class="header-content">
      <div class="header-left">
        <div class="header-left-logo">
          <img src="../assets/logo.png" width="40" />
        </div>
        <div class="header-left-text">
          <div class="header-left-text-up">伍卓国际保税产业园</div>
          <div class="header-left-text-down">
            Wuzhuo International Bonded Industrial Park
          </div>
        </div>
      </div>
      <div class="header-right">
        <router-link to="/aboutus" class="header-right-item"
          >关于我们</router-link
        >
        <router-link to="/bussiness" class="header-right-item"
          >招商引资</router-link
        >
        <router-link to="/policy" class="header-right-item"
          >园区政策</router-link
        >
        <router-link to="/" class="header-right-item">首页</router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from "vue";

const fixed = ref(false);
window.addEventListener("scroll", () => {
  const sTop = document.documentElement.scrollTop || document.body.scrollTop;
  if (sTop > 0 && !fixed.value) {
    fixed.value = true;
  } else if (sTop === 0 && fixed.value) {
    fixed.value = false;
  }
});
</script>

<style lang="less" scoped>
.header {
  position: absolute;
  z-index: 1005;
  width: 100%;
  padding: 0 60px;
  color: #fff;
  background-color: transparent;
  &:hover {
    transition: all 0.3s;
    color: #000000;
    background-color: #ffffff !important;
  }

  &-content {
    display: flex;
    align-items: center;
    margin: 0 auto;
    // width: 1480px;
    height: 60px;
  }

  &-left {
    display: flex;
    align-items: center;
    height: 40px;
    margin-left: 160px;

    &-logo {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;

      &-img {
        width: 100%;
        height: 100%;
      }
    }

    &-text {
      margin-left: 20px;
      display: flex;
      flex-direction: column;

      &-up {
        height: 32px;
        line-height: 32px;
        font-size: 24px;
        letter-spacing: 5.3px;
        font-weight: 600;
      }

      &-down {
        margin-top: 1px;
        font-size: 12px;
      }
    }
  }

  &-right {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    flex: 1;
    margin-right: 30px;

    &-item {
      padding: 0 20px;
      font-weight: 600;
      font-size: 16px;
      height: 60px;
      line-height: 60px;

      &:hover {
        transition: color background-color 0.3s;
        // color: #3894ff;
        border-bottom: 2px solid rgb(23, 99, 173);
        // background-color: #cbcbcbc4;
      }
    }
  }
}

.fixed {
  position: fixed !important;
  transition: all 0.3s;
  color: #000000;
  background-color: #ffffff !important;
}
</style>
