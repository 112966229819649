<template>
  <div class="footer">
    <div class="footer-left">
      <div class="footer-left-name footer-left-item">伍卓国际保税产业园</div>
      <div class="footer-left-address footer-left-item">
        地址：武汉市东湖新技术开发区光谷三路777-12号伍卓国际保税产业园
      </div>
      <div class="footer-left-phone footer-left-item">电话：02787888861</div>
      <div class="footer-left-phone footer-left-item">鄂ICP备2024060376号</div>
    </div>
    <div class="footer-right"></div>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="less" scoped>
.footer {
  height: 300px;
  background-color: rgb(45, 45, 45);
  color: #fff;
  padding: 60px 220px;
  display: flex;
  &-left {
    display: flex;
    flex-direction: column;
    &-item {
      font-size: 16px;
      margin-top: 30px;
      &:first-child {
        margin-top: 0;
      }
    }
    &-name {
      font-size: 20px !important;
      font-weight: 600;
    }
  }
}
</style>
