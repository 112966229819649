<template>
  <div class="rightStick">
    <div class="rightStick-title">企业入驻咨询</div>
    <div class="rightStick-Qrcode">
      <div class="rightStick-Qrcode-box">
        <div class="rightStick-Qrcode-box-surname">张先生：</div>
        <img src="../assets/stick-qrcode-1.png" alt="" />
      </div>
      <div class="rightStick-Qrcode-box">
        <div class="rightStick-Qrcode-box-surname">陈女士：</div>
        <img src="../assets/stick-qrcode-2.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup></script>

<style lang="less">
.rightStick {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 10px;
  bottom: 20px;
  width: 300px;
  border: 1px solid rgb(194, 221, 235);
  padding: 20px;
  border-radius: 5px;
  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }
  background: linear-gradient(
    to bottom,
    rgba(142, 192, 217, 0.563),
    rgba(142, 192, 217, 0.203) 50%,
    rgba(72, 184, 158, 0.436)
  );
  &-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  &-Qrcode {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    &-box {
      margin-top: 10px;
      display: flex;
      flex: 1;
      align-items: center;
      text-align: center;

      &:first-child {
        margin-top: 0;
      }
    }
    img {
      width: 130px;
    }
  }
}
</style>
