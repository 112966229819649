<template>
  <div class="home">
    <banner :bannerInfo="bannerInfo" />
    <div class="home-content">
      <div class="home-content-introduce">
        <homeItem :info="introduce_info">
          <div
            class="introduce-item-content"
            v-for="(item, index) in introduce_info.content"
            :key="index"
          >
            <span
              v-for="(content, _index) in item"
              :key="_index"
              class="introduce-item-content-normal"
              :class="{
                'introduce-item-content-import': content.type === 'import',
              }"
            >
              {{ content.text }}
            </span>
          </div>
        </homeItem>
      </div>
      <div class="home-content-introduce">
        <homeItem :info="location_info" class="location">
          <div class="location-content">
            <div class="location-content-left">
              <div
                class="location-content-left-item"
                v-for="(item, index) in location_info.content"
                :key="index"
              >
                <div class="location-content-left-item-left">
                  {{ item.left }}
                </div>
                <div class="location-content-left-item-right">
                  {{ item.right }}
                </div>
              </div>
            </div>
            <div class="location-content-right">
              <img src="../assets/home-position-bg-1.png" alt="" />
              <img src="../assets/home-position-bg-2.png" alt="" />
            </div>
          </div>
        </homeItem>
      </div>
      <div class="home-content-introduce">
        <homeItem :info="park_info" class="park">
          <div class="park-content">
            <div
              class="park-content-item"
              v-for="(item, index) in park_info.content"
              :key="index"
            >
              <div class="park-content-item-up">{{ item.left }}</div>
              <div class="park-content-item-down">
                {{ item.right }}
              </div>
            </div>
          </div>
          <div class="park-bg">
            <img src="../assets/home-park-bg-1.png" alt="" />
          </div>
        </homeItem>
      </div>
      <div class="home-content-introduce">
        <homeItem :info="resource_info" class="resource">
          <div class="resource-content">
            <div class="resource-content-text">
              {{ resource_info.content }}
            </div>
            <div class="resource-content-bottom">
              <div
                class="resource-content-bottom-item"
                v-for="(item, index) in resource_info.bottom"
                :key="index"
              >
                <div class="resource-content-bottom-item-content">
                  <div class="resource-content-bottom-item-content-icon">
                    <img :src="item.icon" alt="" />
                  </div>
                  <div class="resource-content-bottom-item-content-title">
                    {{ item.title }}
                  </div>
                  <div class="resource-content-bottom-item-content-desc">
                    <div class="resource-content-bottom-item-content-desc-num">
                      {{ item.desc.num }}
                    </div>
                    <div class="resource-content-bottom-item-content-desc-unit">
                      {{ item.desc.unit }}
                    </div>
                  </div>
                </div>
                <div
                  class="resource-content-bottom-item-divide"
                  v-if="index !== resource_info.bottom.length - 1"
                ></div>
              </div>
            </div>
          </div>
        </homeItem>
      </div>

      <div class="home-content-introduce">
        <homeItem :info="overview_info" class="overview">
          <div class="location-content">
            <div class="location-content-left">
              <div
                class="location-content-left-item"
                v-for="(item, index) in overview_info.content"
                :key="index"
              >
                <div class="location-content-left-item-left">
                  {{ item.left }}
                </div>
                <div class="location-content-left-item-right">
                  {{ item.right }}
                </div>
              </div>
            </div>
          </div>
        </homeItem>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import homeItem from "@/components/home/introduce-item.vue";
import banner from "@/components/banner.vue";
import { useRouter } from "vue-router";
import { onMounted } from "vue";
import { scrollToTop } from "@/utils";

const bannerInfo = {
  title: "中国光谷.高新科技国际保税园",
  tip: "依托光谷高新科技产业链，与世界高科企业共成长",
  img: require("../assets/home-banner-bg-1.png"),
};

const introduce_info = {
  title: "项目概况",
  entitle: "PROJECT OVERVIEW",
  content: [
    [
      {
        type: "normal",
        text: "伍卓国际保税产业园位于光谷东湖综合保税区内，属于中国（湖北）自有贸易试验区武汉片区核心区，",
      },
      {
        type: "import",
        text: "总建筑面积95493.75㎡。",
      },
      {
        type: "normal",
        text: "项目重点聚焦",
      },
      {
        type: "import",
        text: "高科技和跨境贸易领域，",
      },
      {
        type: "normal",
        text: "旨在通过产业企业聚集发展，主力东湖综合保税区高科技产业领域的",
      },
      {
        type: "import",
        text: "跨境贸易业务发展。",
      },
    ],
    [
      {
        type: "normal",
        text: "光谷东湖综合综保区是湖北省首个综合保税区，充分发挥保税加工、保税物流、货物贸易、服务贸易功能，已打造形成了保税加工制造、国际生物医药保税平台、跨境电商等九大平台。是目前中国内地开放层次最高、优惠政策最多、功能最齐全的综合功能区，为企业提供全方位的发展主力，为企业跨境贸易提供便利。",
      },
    ],
  ],
  isReverse: false,
};
const location_info = {
  title: "区位优势",
  entitle: "LOCATION ADVANTAGE",
  tip: "多维立体交通, 物流通达全球",
  content: [
    {
      left: "轨道",
      right:
        "武汉“最快地铁”轨道交通19号线、“光谷电子号“直达园区，1小时通达全武汉",
    },
    {
      left: "公路",
      right:
        "5分钟抵达武汉绕城高速，与国家公路无缝对接，可直 达我国主要口岸城市",
    },
    {
      left: "铁路",
      right:
        "30分钟到达武汉火车站，45分钟到达中欧班列铁路中 心，能实现与中亚、俄罗斯及欧洲地区的货物直达",
    },
    {
      left: "水运",
      right:
        "东湖综保区直接管辖一类开放口岸花山港，到45分钟到 达阳逻港，与日韩及东盟国家可实现直航，与欧美通过 长江黄金水道可实现江海联运",
    },
    {
      left: "航空",
      right:
        "1小时抵达到亚洲最大货运机场花湖机场，1小时到达天 河机场，可直达五大洲20多个主要发达国家，实现与国 家空港无缝对接",
    },
  ],
};
const park_info = {
  title: "园区优势",
  entitle: "PARK ADVANTAGE",
  tip: "产城互融, 宜业宜居宜行",
  content: [
    {
      left: "宜业",
      right:
        "拥有5A级写字楼6万方，国际标准厂房仓库近80万方，为企业提供多样化办公生产研发承载空间",
    },
    {
      left: "宜居",
      right:
        "拥有高品质生活配套26万方，其中包括5星级标准酒店、高端青年公寓、医疗配套齐全，提供多种便利生活服务。园区内入驻有华中首家跨境电商“即买即提”商业体——跨境购品源生活广场，给企业员工提供多种消费选择。",
    },
    {
      left: "宜行",
      right:
        "交通方式多种多样，多路公交与地铁2号线、量子号有轨电车无缝接驳，地铁19号线、“光谷电子号”空中轨道直达园区，园区内微循环公交可根据企业需求个性化定制站点。",
    },
  ],
};

const resource_info = {
  title: "资源优势",
  entitle: "RESOURCE ADVANTAGE",
  tip: "人才科研资源聚集，打通科技成果和人才双向交流的出海口",
  content:
    "东湖新技术开发区聚集了科研、高校人才多种企业发展所需资源；有13个国家级企业技术中心、29个国家重点实验室、130余家国家级创新平台，家省部科研机构等多种科研资源；有武汉大学、华中科技大学等90多所高等院校、120多万在校大学生和70余名两院院士；为企业成长提供强有力的人才后盾。",
  bottom: [
    {
      icon: require("../assets/home-resource-icon-1.png"),
      title: "国家级企业技术中心",
      desc: {
        num: 13,
        unit: "个",
      },
    },
    {
      icon: require("../assets/home-resource-icon-2.png"),
      title: "国家重点实验室",
      desc: {
        num: 29,
        unit: "个",
      },
    },
    {
      icon: require("../assets/home-resource-icon-3.png"),
      title: "国家级创新平台",
      desc: {
        num: 130,
        unit: "余家",
      },
    },
    {
      icon: require("../assets/home-resource-icon-4.png"),
      title: "高等院校",
      desc: {
        num: 90,
        unit: "多所",
      },
    },
    {
      icon: require("../assets/home-resource-icon-5.png"),
      title: "在校大学生",
      desc: {
        num: 120,
        unit: "多万",
      },
    },
    {
      icon: require("../assets/home-resource-icon-6.png"),
      title: "两院院士",
      desc: {
        num: 70,
        unit: "余名",
      },
    },
  ],
};

const overview_info = {
  title: "补贴概要",
  entitle: "OVERVIEW OF SUBSIDIES",
  content: [
    {
      left: "场地补贴",
      right: "给予符合条件的企业50%至100%的租金补贴。",
    },
    {
      left: "固定投资补贴",
      right: "给予符合条件的企业上限为300万元/年的优惠补贴。",
    },
    {
      left: "运营补贴",
      right: "给予符合条件的企业上限为50万元/年的优惠补贴。",
    },
    {
      left: "优惠补贴",
      right:
        "针对保税服务新业态类、口岸平台年服务类、物流服务类、大宗商品贸易类、跨境电商类的企业，给予符合条件的企业相关优惠补贴政策。",
    },
  ],
};

onMounted(() => {
  scrollToTop();
});
</script>

<style lang="less" scoped>
.home {
  width: 100%;
  display: flex;
  flex-direction: column;

  &-banner {
    width: 100%;
    height: 700px;
    background: url("../assets/banner_bg.png");
    // background: linear-gradient(to right, rgba(20, 27, 51), rgba(11, 13, 21));
    display: flex;
    flex-direction: column;

    &-up {
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 350px;
      line-height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 425px;
      color: rgba(21, 31, 46, 0.339);
      mix-blend-mode: screen;
    }

    &-down {
      position: absolute;
      z-index: 1;
      top: 300px;
      width: 100%;
      height: 425px;
      line-height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 425px;
      color: rgba(30, 44, 74, 0.7);
    }

    &-title {
      margin-top: 150px;
      margin-left: 30rem;
      color: #fff;
      font-size: 60px;
      letter-spacing: 10px;
    }

    &-tip {
      z-index: 1001;
      height: 60px;
      margin: 60px 0;
      margin-top: 90px;
      width: 700px;
      margin-left: 30rem;
      background-color: rgb(23, 99, 173);
      padding: 10px 30px 10px 10px;
      display: flex;
      align-items: center;
      border-radius: 0 0 20px 0;

      &-text {
        border-radius: 0 0 40px 0;
        color: #fff;
        font-size: 26px;
        letter-spacing: 3px;
      }
    }

    &-bussiness {
      z-index: 1001;
      margin-left: 30rem;
      &-title {
        align-items: center;
        display: flex;
        background-color: rgb(72, 184, 157);
        border-radius: 50px;
        padding: 0 15px;
        height: 30px;
        font-size: 20px;
        margin-bottom: 2px;
        width: 110px;
        margin-top: 60px;
      }

      &-phone {
        margin-left: 2px;
        margin-top: 20px;
        color: #fff;
        display: flex;
        align-items: center;

        &-left {
          display: flex;
          flex-direction: column;
          font-size: 20px;

          &-up {
            display: flex;
            align-items: center;
            text-align: center;

            &-icon {
              margin-top: 3px;
              margin-left: 10px;
              color: white;
              width: 26px;
              height: 26px;
            }
          }

          &-down {
            display: flex;
            align-items: center;

            &-split {
              margin: 0 4px;
              rotate: 20deg;
            }
          }
        }

        &-right {
          height: 60px;
          line-height: 60px;
          margin-left: 20px;
          font-size: 60px;
        }
      }
    }
  }

  &-content {
    &-introduce {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 30px 0;
      font-size: 20px;
      &:nth-child(even) {
        background-color: #f5f5f5;
      }
    }
  }
}

.location {
  &-content {
    display: flex;
    margin-top: 40px;
    font-size: 16px;
    &-left {
      flex: 1;
      &-item {
        display: flex;
        margin-top: 50px;
        &:first-child {
          margin-top: 0;
        }
        &-left {
          margin-top: 3px;
          height: 32px;
          display: flex;
          align-items: center;
          padding: 4px 10px;
          border-radius: 6px;
          color: #fff;
          border: 1px solid rgb(68, 98, 150);
          background-color: rgb(68, 98, 150);
        }
        &-right {
          flex: 1;
          align-self: flex-start;
          margin-left: 12px;
          font-size: 20px;
          color: #000;
          letter-spacing: 2px;
        }
      }
    }
    &-right {
      margin-left: 60px;
      display: flex;
      flex-direction: column;
      color: #000;
      img {
        margin-top: 30px;
        object-fit: fill;
        width: 540px;
        &:first-child {
          margin-top: 0;
        }
      }
    }
  }
}

.park {
  &-content {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-top: 40px;
    &-item {
      display: flex;
      flex-direction: column;
      margin-left: 50px;
      &:first-child {
        margin-left: 0;
      }
      &-up {
        width: 54px;
        height: 32px;
        display: flex;
        align-items: center;
        padding: 4px 10px;
        border-radius: 6px;
        color: #fff;
        border: 1px solid rgb(68, 98, 150);
        background-color: rgb(68, 98, 150);
      }
      &-down {
        color: #000;
        line-height: 26px;
        margin-top: 20px;
        font-size: 20px;
      }
    }
  }
  &-bg {
    width: 1200px;
    margin-top: 20px;
    img {
      width: 100%;
    }
  }
}

.resource {
  &-content {
    display: flex;
    flex-direction: column;
    &-text {
      margin-top: 20px;
      color: #000;
      font-size: 20px;
    }
    &-bottom {
      display: flex;
      margin-top: 60px;
      align-items: center;
      &-item {
        display: flex;
        align-items: center;
        &-content {
          display: flex;
          flex-direction: column;
          align-items: center;
          text-align: center;
          &-icon {
            width: 74px;
            height: 74px;
            img {
              width: 100%;
            }
          }
          &-title {
            max-width: 200px;
            margin-top: 20px;
            font-weight: 600;
            font-size: 24px;
            color: #42b096;
            height: 94px;
          }
          &-desc {
            margin-top: 10px;
            font-size: 24px;
            font-weight: 600;
            display: flex;
            &-num {
              font-size: 60px;
              font-weight: 400;
            }
            &-unit {
              align-self: flex-end;
              font-size: 16px;
              max-width: 40px;
              margin-bottom: 12px;
              margin-left: 4px;
            }
          }
        }
        &-divide {
          border-top: 1px solid #42b096;
          width: 60px;
          margin: 0 20px;
          margin-bottom: 180px;
        }
      }
    }
  }
}

.streamline {
  position: relative;
  width: 300px; /* 线的总宽度 */
  height: 20px; /* 线的高度 */
  background: linear-gradient(to right, blue, transparent); /* 背景渐变 */
  border-radius: 10px 0 0 10px; /* 左侧圆角 */
  margin-left: 50px;
  clip-path: polygon(
    0% 20%,
    85% 0%,
    100% 50%,
    85% 100%,
    0% 80%
  ); /* 多边形裁剪路径 */
}
</style>
